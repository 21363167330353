<template>
  <div>
    <wd-navbar light>
      <div
        slot="left"
        style="
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        "
      >
        <img :src="logo" style="max-height: 45px" alt="" />
      </div>
      <div class="title" @click="showTs">首页</div>
      <div
        slot="right"
        style="
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <a
          :href="'tel:' + rolePhoneNum"
          style="
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          "
          ><img
            src="../../assets/img/phone.png"
            style="max-height: 25px"
            alt=""
        /></a>
      </div>
    </wd-navbar>
    <wd-swipe>
      <wd-swipe-item
        v-for="(item, index) in pics"
        :key="index"
        style="height: 180px"
      >
        <img :src="item.url" style="width: 100%; height: 100%" alt="" />
      </wd-swipe-item>
    </wd-swipe>

    <wd-grid :column="2">
      <wd-grid-item
        icon="picture"
        v-for="(item, index) in menuList"
        v-if="item.show"
        :key="index"
        class="listBtn"
      >
        <div @click="goto(item.url)">
          <div
            slot="icon"
            style="width: 100%; display: flex; justify-content: center"
          >
            <img :src="item.pic" alt="" style="max-width: 50px" />
          </div>
          <div
            slot="text"
            style="
              margin-top: 10px;
              font-size: 14px;
              font-family: '微软雅黑 Light';
            "
          >
            {{ item.text }}
          </div>
        </div>
      </wd-grid-item>
    </wd-grid>

    <wd-tabbar v-model="tabbar" fixed border>
      <wd-tabbar-item icon="wd-icon-bags" class="tabbarClass"
        >首页</wd-tabbar-item
      >
      <wd-tabbar-item icon="wd-icon-setting" class="tabbarClass" to="/configure"
        >设置</wd-tabbar-item
      >
    </wd-tabbar>
    <wd-popup v-model="show" position="top" :style="{ height: '200px' }">
      location地址： {{ url }}<br />
      phoneNum: {{ userId }}
    </wd-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      logo: '',
      tabbar: 0,
      ts: 0,
      pics: [],
      imageObject: null,
      menuList: [
        {
          url: '/pay',
          text: '综合缴费',
          show: true,
          pic: require('../../assets/img/icon1.svg'),
        },
        {
          url: '/selectEquip',
          text: '用量查询',
          show: true,
          pic: require('../../assets/img/icon2.svg'),
        },
        {
          url: '/control',
          text: '实时控制',
          show: true,
          pic: require('../../assets/img/icon3.svg'),
        },
        {
          url: '/config',
          text: '费用配置',
          show: true,
          pic: require('../../assets/img/icon4.svg'),
        },
        {
          url: '/selectObject',
          text: '缴费记录',
          show: true,
          pic: require('../../assets/img/icon5.svg'),
        },
        {
          url: '/power',
          text: '综合能耗',
          show: true,
          pic: require('../../assets/img/icon6.svg'),
        },
      ],
      roleKey: null,
      rolePhoneNum: null,
      url: '',
      userId: '',
    }
  },
  created() {
    this.url = window.location.href
    let userId = (this.userId = localStorage.getItem('phoneNum'))
    // if (userId && userId.trim() !== '') {
    this.$http.get('/external/appUser/' + userId).then((res) => {
      if (res.code == 200) {
        let object = res.user
        localStorage.setItem('appUserId', object.appUserId)
        localStorage.setItem('phoneNum', object.phoneNum)
        localStorage.setItem('roleIds', object.roleIds)
        localStorage.setItem('rolePhoneNum', object.rolePhoneNum)
        this.rolePhoneNum = object.rolePhoneNum
      }
    })
    // }

    let showControl = localStorage.getItem('showControl')
    if (showControl == 'false') {
      this.menuList[1].show = false
      this.menuList[2].show = false
      this.menuList[3].show = false
      this.menuList[5].show = false
    }
    this.roleKey = localStorage.getItem('roleKey')
    this.imageObject = new Image()
    this.handlePics()
  },
  mounted() {
    let urlc = this.roleKey + '.png?' + new Date().getTime()
    this.logo = './static/img/role/logo/' + urlc
  },
  methods: {
    showTs() {
      this.ts = this.ts + 1
      if (this.ts == 5) {
        this.show = true
      }
    },
    goto(url) {
      this.$router.push(url)
    },
    handlePics() {
      this.pics = []
      let url = window.origin + window.location.pathname
      // 轮播图
      for (let i = 1; i < 6; i++) {
        let src = url + '/static/img/role/' + this.roleKey + '/' + i + '.jpg'
        this.CheckImgExists(src).then((res) => {
          if (res) {
            let obj = { url: '' }
            obj.url =
              './static/img/role/' +
              this.roleKey +
              '/' +
              i +
              '.jpg?' +
              new Date().getTime()
            this.pics.push(obj)
          }
        })
      }
    },
    CheckImgExists(imgurl) {
      return new Promise(function (resolve, reject) {
        let ImgObj = new Image() //判断图片是否存在
        ImgObj.src = imgurl
        ImgObj.onload = function (e) {
          if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
            resolve(true)
          } else {
            reject(false)
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.listBtn {
  border-bottom: 1px solid rgba(#eee, 0.7);
  &:nth-child(n) {
    border-right: 1px solid rgba(#eee, 0.7);
  }
}
.tabbarClass {
  /deep/ .wd-tabbar-item__label {
    font-size: 14px;
    font-family: 微软雅黑;
    margin-top: 5px;
  }
}
</style>
